import React from 'react';

import {
    exportCallsToExcel,
    generateCallsExportData,
    normalizePhone,
} from '../../../utils/functions';
import { CallsRecordInterface, IUser } from '../../../utils/types';
import { callsDateRanges } from '../CallsComponent/CallsComponent.consts';
import { CallsFilterParams } from '../CallsComponent/CallsComponent.types';
import DurationFilter from '../DurationFilter/DurationFilter';
import ManagerFilter from '../ManagerFilter/ManagerFilter';
import PhoneFilter from '../PhoneFilter/PhoneFilter';
import RadionButtonFilter from '../RadionButtonFilter/RadionButtonFilter';
import StyledFiltersWrapper from './CallsFilters.style';
import ExcelIcon from '../../../assets/images/excel.png';

type CallsFiltersProps = {
    // eslint-disable-next-line no-unused-vars
    filterChangeHandler: (value: string, field?: string) => void;
    disabledAllFilters: boolean;
    filter: CallsFilterParams;
    user: IUser | null;
    hideManagerFilter: boolean;
    callRecords: CallsRecordInterface[];
};

const CallsFilters = ({
    filterChangeHandler,
    disabledAllFilters,
    filter,
    user,
    hideManagerFilter,
    callRecords,
}: CallsFiltersProps) => {
    const isAdmin = user?.isAdmin;

    const onClickHandler = () => {
        const exportData = generateCallsExportData(callRecords);
        exportCallsToExcel(exportData);
    };
    return (
        <StyledFiltersWrapper>
            <div className="calls-filters__filter-row">
                {!hideManagerFilter && (
                    <ManagerFilter
                        filterChangeHandler={filterChangeHandler}
                        filterParam={isAdmin ? filter.manager : String(user?.userId)}
                        disabledFilter={disabledAllFilters || !isAdmin}
                    />
                )}
                <RadionButtonFilter
                    filterChangeHandler={filterChangeHandler}
                    filterParams={filter}
                    filterType="callDirection"
                    disabledFilter={disabledAllFilters}
                />
            </div>
            <div className="calls-filters__filter-row">
                <PhoneFilter
                    filterChangeHandler={filterChangeHandler}
                    phone={normalizePhone(filter.phone)}
                    disabledFilter={disabledAllFilters}
                />
                <DurationFilter
                    filterChangeHandler={filterChangeHandler}
                    duration={filter.duration}
                    disabledFilter={disabledAllFilters}
                />
            </div>
            <div className="calls-filters__filter-row">
                <p className="calls-filters__date-filter-label">Выбрать дату:</p>
                {callsDateRanges.map((dateRange) => (
                    <div key={dateRange} className="calls-filters__date-filter">
                        <p>{dateRange === 'dateFrom' ? 'с' : 'по'}</p>
                        <input
                            type="date"
                            value={filter[dateRange]}
                            onChange={(e) => filterChangeHandler(e.target.value, dateRange)}
                        />
                    </div>
                ))}
            </div>
            <div className="export__button" onClick={onClickHandler}>
                <img src={ExcelIcon} />
                <p className="export__text">Экспорт таблицы</p>
            </div>
        </StyledFiltersWrapper>
    );
};

export default CallsFilters;
