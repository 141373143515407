import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { clsx } from 'clsx';

import { getDate, getGivenOutOffer, getManagersList, getUserName } from '../../../utils/functions';
import { getAllUsers } from '../../../store/slices/staffSlice';
import {
    resetCurrentTask,
    selectClientTask,
    updateTask,
} from '../../../store/slices/clientTaskSlice';
import { useAppDispatch } from '../../../store';
import { selectClientCard } from '../../../store/slices/clientCardSlice';
import { userSelector } from '../../../store/slices/userSlice';
import BulletListIcon from '../../../assets/images/bullet-list-icon.png';
import CustomSelect from '../../../components/Common/CustomSelect/CustomSelect';
import StatusLine from '../StatusLine/StatusLine';
import StyledClientTaskItem from './ClientTaskItem.style';
import { IUser, TaskProductENUM } from '../../../utils/types';
import { userAPI } from '../../../api/userApi/userApi';

const ClientTaskItem = () => {
    const dispatch = useAppDispatch();
    const { currentTask } = useSelector(selectClientTask);
    const { company } = useSelector(selectClientCard);
    const { user } = useSelector(userSelector);
    const [executorsUserList, setExecutorsUserList] = useState<IUser[]>([]);
    const hasRightForAssigning = user?.isAdmin || company?.manager?.userId === user?.userId;
    const givenOffer = getGivenOutOffer(currentTask?.offers);
    const isTaskClosed = Boolean(givenOffer || currentTask?.closeReason);
    const header = `Заявка № ${currentTask?.taskId || ''} ${
        currentTask?.postscript ? `(${currentTask.postscript})` : ''
    }`;
    const startWorkDate = getDate(currentTask?.createdAt || '', 'date with time', true);
    const finishWorkDate = getDate(currentTask?.closeDate || '', 'date with time', true);

    const managerSelection = (value: string, managerType: 'executor' | 'coExecutor') => {
        let currentValue: string | null = value;

        // need to remove co-executor from the task in case
        if (value === '0' && managerType === 'coExecutor') {
            currentValue = null;
        }
        if (!currentTask || !company) return;

        dispatch(
            updateTask({
                type: currentTask.type || TaskProductENUM.BANK_GUARANTEE,
                taskId: currentTask.taskId,
                companyId: company.companyId,
                [`${managerType}Id`]: currentValue ? Number(currentValue) : null,
            }),
        );
    };
    const executors = [{ fullName: 'нет', userId: 0 }, ...getManagersList(executorsUserList)];

    useEffect(() => {
        dispatch(resetCurrentTask);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (!currentTask) return;
        (async () => {
            try {
                const response = await userAPI.getAllUsers({
                    onlyActiveUsers: true,
                    positionName: 'OSKO',
                });

                if (!response?.data) {
                    throw new Error('No executors exists');
                }

                setExecutorsUserList(response.data);
            } catch (err: any) {
                console.error('"ClientTaskItem":', err.message);
            }
        })();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentTask?.executor?.userId]);

    const getUserId = (managerType: 'executor' | 'coExecutor') => {
        let id = 0;

        if (currentTask && managerType in currentTask) {
            id = currentTask[managerType]?.userId ? Number(currentTask[managerType]?.userId) : 0;
        }

        return id;
    };

    const getSelectWithManagers = (
        managerType: 'executor' | 'coExecutor',
        currentManager?: IUser,
    ) => {
        const executorsList = [...executors];

        const isCurrentManagerInList = executorsList.find(
            (item) => item.userId === currentManager?.userId,
        );

        if (!isCurrentManagerInList && currentManager) {
            executorsList.push({
                fullName: getUserName(currentManager),
                userId: currentManager.userId,
            });
        }

        const hasRightsToChange =
            hasRightForAssigning ||
            (managerType === 'coExecutor' && user?.userId === getUserId('executor'));
        return (
            <CustomSelect
                width={262}
                height={20}
                backgroundImage={BulletListIcon}
                backgroundColor="#f9f9f9"
            >
                <select
                    className={clsx({
                        'task-info__disabled-select': isTaskClosed,
                    })}
                    value={getUserId(managerType)}
                    onChange={(e) => managerSelection(e.target.value, managerType)}
                    disabled={isTaskClosed || !hasRightsToChange}
                    onFocus={() =>
                        dispatch(getAllUsers({ onlyActiveUsers: true, positionName: 'OSKO' }))
                    }
                >
                    {executorsList.map((item) => (
                        <option key={item.userId} value={item.userId}>
                            {item.fullName}
                        </option>
                    ))}
                </select>
            </CustomSelect>
        );
    };

    return (
        <StyledClientTaskItem>
            <div
                className={clsx('client-task-item__header', {
                    'task-info__disabled-header': isTaskClosed,
                })}
            >
                <h3>{header}</h3>
            </div>
            <div className="client-task-item__body">
                <div className="client-task-item__row">
                    <p>
                        Дата начала работы: <span>{startWorkDate}</span>
                    </p>
                    <div className="client-task-item__executor">
                        Исполнитель:{' '}
                        {getSelectWithManagers('executor', currentTask?.executor as IUser)}
                    </div>
                </div>
                <div className="client-task-item__row">
                    <p>
                        Дата окончания работы: <span>{finishWorkDate}</span>
                    </p>
                    <div className="client-task-item__executor">
                        Соисполнитель:{' '}
                        {getSelectWithManagers('coExecutor', currentTask?.coExecutor as IUser)}
                    </div>
                </div>
                {isTaskClosed && (
                    <div className="client-task-item__row">
                        <p>
                            Менеджер закрывший заявку:{' '}
                            <span>
                                {currentTask?.userWhoClosedTask
                                    ? currentTask?.userWhoClosedTask.fullName
                                    : 'нет'}
                            </span>
                        </p>
                    </div>
                )}
                <h4 className="client-task-item__subheader">Этапы работы с задачей (Статус)</h4>
                <StatusLine status={currentTask?.status || null} type={currentTask?.type} />
            </div>
        </StyledClientTaskItem>
    );
};

export default ClientTaskItem;
