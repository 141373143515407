import React from 'react';

import GearsIcon from '../../../../assets/images/clients/gears-icon.png';
import TrashIcon from '../../../../assets/images/clients/font-trash-icon.png';
import StyledContainer from './CredentialsItem.style';
import { normalizeBankProductForTable } from '../../../../utils/functions';
import { CredentialsWithError } from '../../../../utils/types';
import CredentialsItemContact from '../CredentialsItemContacts/CredentialsItemContacts';
import { useAppDispatch } from '../../../../store';
import { useSelector } from 'react-redux';
import { deleteBankCredential, selectPartner } from '../../../../store/slices/partnerSlice';
import ConfirmModal from '../../../Common/ConfirmModal/ConfirmModal';

type CredentialsItemProps = {
    cred: CredentialsWithError;
    credIndex: number;
    // eslint-disable-next-line no-unused-vars
    setEditingCredentials: (value: number) => void;
    isAdmin: boolean;
};

const CredentialsItem = ({
    cred,
    credIndex,
    setEditingCredentials,
    isAdmin,
}: CredentialsItemProps) => {
    const [deletingCredentialId, setDeletingCredentialId] = React.useState<
        number | null | undefined
    >(null);

    const dispatch = useAppDispatch();
    const { currentPartner } = useSelector(selectPartner);

    const onDeleteCredentialsClick = (credentialId?: number) => {
        setDeletingCredentialId(credentialId);
    };

    const deleteCredentialHandler = (credentialId: number | null) => {
        if (!(credentialId && currentPartner?.bankId)) {
            return;
        }

        dispatch(
            deleteBankCredential({
                bankId: currentPartner?.bankId,
                credentialId,
            }),
        );
        setDeletingCredentialId(null);
    };
    const productType = normalizeBankProductForTable(cred.type);

    return (
        <StyledContainer>
            <div key={`cred - ${credIndex}`} className="credentials-item__table-row">
                <p>{productType}</p>
                <p>{cred.lkOperator}</p>
                <a target="_blank" rel="noopener noreferrer" href={cred.lkLink}>
                    {cred.lkLink}
                </a>
                <p>{cred.login}</p>
                <p>{cred.password}</p>
                <div>
                    {cred.contacts.map((contact, contactIndex) => (
                        <CredentialsItemContact
                            key={contact.bankCredentialContactId}
                            contact={contact}
                            contactIndex={contactIndex}
                            credIndex={credIndex}
                        />
                    ))}
                </div>
                {isAdmin && (
                    <img src={GearsIcon} onClick={() => setEditingCredentials(credIndex)} />
                )}
                {isAdmin && (
                    <img
                        src={TrashIcon}
                        onClick={() => onDeleteCredentialsClick(cred.bankCredentialId)}
                    />
                )}
            </div>
            {deletingCredentialId && (
                <ConfirmModal
                    close={() => setDeletingCredentialId(null)}
                    confirmAction={() => deleteCredentialHandler(deletingCredentialId)}
                    width={280}
                    height={85}
                    headText="Удалить ЛК?"
                />
            )}
        </StyledContainer>
    );
};

export default CredentialsItem;
