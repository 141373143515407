import {
    BankProductDoc,
    BankProductRequest,
    PartnerCredential,
    PartnerInterface,
    ProductListInterface,
    StopRegionsInterface,
    PartnerContact,
    EmailWithAdditInfoType,
    PhoneWithAdditInfoType,
    CreatePartnerDocument,
    BankDocumentSectionTypes,
} from '../../utils/types';

/* eslint-disable */
export enum partnerApiPath {
    CRUD_PARTNER = '/bank',
    STOP_REGIONS = '/bank/stop-regions',
    PRODUCT_LIST = '/bank/product-lists',
    BANK_PRODUCT = '/products',
    BANK_CREDENTIALS = '/credentials',
    BANK_CONTACTS = '/contacts',
    CRUD_DOCUMENTS = '/documents',
}

export enum bankProductCategoryEnum {
    COMMERCIAL_GUARANTEE = 'commercialGuarantee',
    BANK_GUARANTEE = 'bankGuarantee',
    LOAN = 'loan',
}
/* eslint-enable */

interface BaseCrudPartnerInterface {
    logo?: string;
    isDisplayedInList?: boolean;
    isPriority?: boolean;
    additInfo?: string;
    documents?: CreatePartnerDocument[];
    credentials?: PartnerCredential[];
    bankContractExecution?: BankProductRequest;
    bankAdvanceRefund?: BankProductRequest;
    bankGuaranteeObligation?: BankProductRequest;
    applicationSecurity?: BankProductRequest;
    commercialContractExecution?: BankProductRequest;
    commercialAdvanceRefund?: BankProductRequest;
    commercialGuaranteeObligation?: BankProductRequest;
}

export interface CreatePartnerPropsType extends BaseCrudPartnerInterface {
    name: string;
}

export interface UpdatePartnerPropsType extends BaseCrudPartnerInterface {
    name?: string;
    bankId: number;
}

export type GetOnePartnerPropsType = {
    bankId: number;
};

export type GetAllPartnersPropsType = {
    federalLaw?: string;
    guaranteeType?: string;
    customerFromList?: number;
    term?: number;
    limit?: number;
    input?: string;
    onlyEnabled?: boolean;
    withAllProducts?: boolean;
    abortSignal?: AbortSignal;
    categories?: bankProductCategoryEnum[];
};

export type GetPartnersResponseType =
    | {
          data: { banksList: PartnerInterface[]; total: { records: number } };
          message: string;
      }
    | undefined;

export type GetOnePartnerResponseType =
    | {
          data: PartnerInterface;
          message: string;
      }
    | undefined;

export type GetStopRegionsResponseType =
    | {
          data: StopRegionsInterface[];
          message: string;
      }
    | undefined;

export type GetProductListResponseType =
    | {
          data: ProductListInterface[];
          message: string;
      }
    | undefined;

export type CreateProductListPropsType = {
    name: string;
};

export type StatusResponseType = number | undefined;

export type CreatePartnerResponseType =
    | {
          data: PartnerInterface;
          message: string;
      }
    | undefined;

export interface UpdateBankProductProps {
    bankId: number;
    bankProductId: number;
    limit?: string;
    rateFrom?: string;
    term?: string;
    hasFZ44?: boolean;
    hasFZ223?: boolean;
    hasPP615?: boolean;
    experience?: string;
    age?: string;
    worksWithIP?: boolean;
    worksWithAO?: boolean;
    baseCommission?: string;
    clientCommission?: string;
    additInfo?: string;
    stopRegions?: string[];
    lists?: string[];
    documents?: BankProductDoc[];
    isActive?: boolean;
}

export interface UpdateCredentialProps {
    bankId: number;
    credentialId: number;
    lkOperator?: string;
    lkLink?: string;
    login?: string;
    password?: string;
    contacts?: PartnerContact[];
    type?: string;
}
export interface DeleteCredentialProps {
    bankId: number;
    credentialId: number;
}

export interface CreateCredentialProps {
    bankId: number;
    lkOperator: string;
    lkLink: string;
    login: string;
    password: string;
    contacts: PartnerContact[];
    type?: string;
}

export interface UpdateCredentialContactProps {
    bankId: number;
    credentialId: number;
    contactId: number;
    name?: string;
    emails?: EmailWithAdditInfoType[];
    phoneNumbers?: PhoneWithAdditInfoType[];
}

export interface CreateCredentialContactProps {
    bankId: number;
    credentialId: number;
    name: string;
    emails: EmailWithAdditInfoType[];
    phoneNumbers: PhoneWithAdditInfoType[];
}

export interface DeleteCredentialContactProps {
    bankId: number;
    credentialId: number;
    contactId: number;
}

export interface CreateBankDocProps {
    bankId: number;
    name: string;
    section: BankDocumentSectionTypes;
    file: string;
}

export interface DeleteBankDocProps {
    bankId: number;
    documentId: number;
}

export interface CreateBankProductDocProps {
    bankId: number;
    productId: number;
    name: string;
    file: string;
}

export interface DeleteBankProductDocProps {
    bankId: number;
    productId: number;
    documentId: number;
}

export interface DeleteProductListProps {
    productListId: number;
}

export type GetAllCredentialsResponseType =
    | {
          data: PartnerCredential[];
          message: string;
      }
    | undefined;
export interface GetAllCredentialsPropsType {
    type?: string;
}
