import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { toast } from 'react-hot-toast';

import {
    UpdatePartnerPropsType,
    UpdateBankProductProps,
    GetAllPartnersPropsType,
    UpdateCredentialProps,
    UpdateCredentialContactProps,
    CreateCredentialContactProps,
    DeleteCredentialContactProps,
    CreateBankDocProps,
    DeleteBankDocProps,
    CreateBankProductDocProps,
    DeleteBankProductDocProps,
    StatusResponseType,
    CreateCredentialProps,
    DeleteProductListProps,
    GetAllCredentialsPropsType,
    DeleteCredentialProps,
} from './../../../api/partnerApi/partnerApi.types';
import { getError, isResponseOK } from '../../../utils/functions';
import { RootState } from '../..';
import { InitialStateType } from './types';
import { partnerApi } from '../../../api/partnerApi/partnerApi';
import {
    CreatePartnerPropsType,
    CreateProductListPropsType,
    GetOnePartnerPropsType,
} from '../../../api/partnerApi/partnerApi.types';
import {
    PartnerCredential,
    PartnerInterface,
    ProductListInterface,
    StopRegionsInterface,
} from '../../../utils/types';

export const getAllPartners = createAsyncThunk<
    {
        banksList: PartnerInterface[];
        total: { records: number };
    },
    GetAllPartnersPropsType,
    {
        rejectValue: string;
    }
>('partner/getAllPartners', async ({ ...props }, { rejectWithValue, signal }) => {
    try {
        const res = await partnerApi.getAllPartners({ ...props, abortSignal: signal });
        if (res?.message === 'Success') {
            return res.data;
        } else {
            throw res?.data;
        }
    } catch (e) {
        return rejectWithValue(getError(e));
    }
});

export const getOnePartner = createAsyncThunk<
    PartnerInterface,
    GetOnePartnerPropsType,
    {
        rejectValue: string;
    }
>('partner/getOnePartner', async ({ ...props }, { rejectWithValue }) => {
    try {
        const res = await partnerApi.getOnePartner({ ...props });
        if (res?.message === 'Success') {
            return res.data;
        } else {
            throw res?.data;
        }
    } catch (e) {
        return rejectWithValue(getError(e));
    }
});

export const createPartner = createAsyncThunk<
    {
        data: PartnerInterface;
        message: string;
    },
    CreatePartnerPropsType,
    {
        rejectValue: string;
    }
>('partner/createPartner', async ({ ...props }, { rejectWithValue, dispatch }) => {
    try {
        const res = await partnerApi.createPartner({ ...props });
        if (res?.message === 'Success') {
            dispatch(getAllPartners({}));
            const { bankId } = res.data;
            dispatch(getOnePartner({ bankId }));
            return res;
        } else {
            throw res;
        }
    } catch (e) {
        return rejectWithValue(getError(e));
    }
});

export const getStopRegions = createAsyncThunk<
    StopRegionsInterface[],
    {},
    {
        rejectValue: string;
    }
>('partner/getStopRegions', async (_, { rejectWithValue }) => {
    try {
        const res = await partnerApi.getStopRegions();
        if (res?.message === 'Success') {
            return res.data;
        } else {
            throw res?.data;
        }
    } catch (e) {
        return rejectWithValue(getError(e));
    }
});

export const getProductList = createAsyncThunk<
    ProductListInterface[],
    {},
    {
        rejectValue: string;
    }
>('partner/getProductList', async (_, { rejectWithValue }) => {
    try {
        const res = await partnerApi.getProductList();
        if (res?.message === 'Success') {
            return res.data;
        } else {
            throw res?.data;
        }
    } catch (e) {
        return rejectWithValue(getError(e));
    }
});

export const createProductList = createAsyncThunk<
    StatusResponseType,
    CreateProductListPropsType,
    {
        rejectValue: string;
    }
>('partner/createProductList', async ({ ...props }, { rejectWithValue }) => {
    try {
        const res = await partnerApi.createProductList({ ...props });
        if (isResponseOK(res)) {
            return res;
        } else {
            throw res;
        }
    } catch (e) {
        return rejectWithValue(getError(e));
    }
});

export const deleteProductList = createAsyncThunk<
    StatusResponseType,
    DeleteProductListProps,
    {
        rejectValue: string;
    }
>('partner/deleteProductList', async ({ ...props }, { rejectWithValue, dispatch }) => {
    try {
        const res = await partnerApi.deleteProductList({ ...props });
        if (isResponseOK(res)) {
            dispatch(getProductList({}));
            return res;
        } else {
            throw res;
        }
    } catch (e) {
        return rejectWithValue(getError(e));
    }
});

export const createBankCredential = createAsyncThunk<
    StatusResponseType,
    CreateCredentialProps,
    {
        rejectValue: string;
    }
>('partner/createBankCredential', async ({ bankId, ...props }, { rejectWithValue, dispatch }) => {
    try {
        const res = await partnerApi.createBankCredential({ ...props, bankId });
        if (isResponseOK(res)) {
            dispatch(getOnePartner({ bankId }));
            return res;
        } else {
            throw res;
        }
    } catch (e) {
        return rejectWithValue(getError(e));
    }
});

export const updateBankCredential = createAsyncThunk<
    StatusResponseType,
    UpdateCredentialProps,
    {
        rejectValue: string;
    }
>('partner/updateBankCredential', async ({ bankId, ...props }, { rejectWithValue, dispatch }) => {
    try {
        const res = await partnerApi.updateBankCredential({ ...props, bankId });
        if (isResponseOK(res)) {
            dispatch(getOnePartner({ bankId }));
            return res;
        } else {
            throw res;
        }
    } catch (e) {
        return rejectWithValue(getError(e));
    }
});
export const deleteBankCredential = createAsyncThunk<
    StatusResponseType,
    DeleteCredentialProps,
    {
        rejectValue: string;
    }
>(
    'partner/deleteBankCredential',
    async ({ bankId, credentialId }, { rejectWithValue, dispatch }) => {
        try {
            const res = await partnerApi.deleteBankCredential({ credentialId, bankId });
            if (isResponseOK(res)) {
                dispatch(getOnePartner({ bankId }));
                return res;
            } else {
                throw res;
            }
        } catch (e) {
            return rejectWithValue(getError(e));
        }
    },
);

export const updatePartner = createAsyncThunk<
    StatusResponseType,
    UpdatePartnerPropsType,
    {
        rejectValue: string;
    }
>('partner/updatePartner', async ({ bankId, ...props }, { rejectWithValue, dispatch }) => {
    try {
        const res = await partnerApi.updatePartner({
            ...props,
            bankId,
        });
        if (isResponseOK(res)) {
            dispatch(getAllPartners({}));
            dispatch(getOnePartner({ bankId }));
            return res;
        } else {
            throw res;
        }
    } catch (e) {
        return rejectWithValue(getError(e));
    }
});

export const updateBankProduct = createAsyncThunk<
    StatusResponseType,
    UpdateBankProductProps,
    {
        rejectValue: string;
    }
>('partner/updateBankProduct', async ({ bankId, ...props }, { rejectWithValue, dispatch }) => {
    try {
        const res = await partnerApi.updateBankProduct({
            ...props,
            bankId,
        });
        if (isResponseOK(res)) {
            dispatch(getOnePartner({ bankId }));
            return res;
        } else {
            throw res;
        }
    } catch (e) {
        return rejectWithValue(getError(e));
    }
});

export const updateCredentialContact = createAsyncThunk<
    StatusResponseType,
    UpdateCredentialContactProps,
    {
        rejectValue: string;
    }
>(
    'partner/updateCredentialContact',
    async ({ bankId, ...props }, { rejectWithValue, dispatch }) => {
        try {
            const res = await partnerApi.updateCredentialContact({
                ...props,
                bankId,
            });
            if (isResponseOK(res)) {
                dispatch(getOnePartner({ bankId }));
                return res;
            } else {
                throw res;
            }
        } catch (e) {
            return rejectWithValue(getError(e));
        }
    },
);

export const createCredentialContact = createAsyncThunk<
    StatusResponseType,
    CreateCredentialContactProps,
    {
        rejectValue: string;
    }
>(
    'partner/createCredentialContact',
    async ({ bankId, ...props }, { rejectWithValue, dispatch }) => {
        try {
            const res = await partnerApi.createCredentialContact({
                ...props,
                bankId,
            });
            if (isResponseOK(res)) {
                dispatch(getOnePartner({ bankId }));
                return res;
            } else {
                throw res;
            }
        } catch (e) {
            return rejectWithValue(getError(e));
        }
    },
);

export const deleteCredentialContact = createAsyncThunk<
    StatusResponseType,
    DeleteCredentialContactProps,
    {
        rejectValue: string;
    }
>(
    'partner/deleteCredentialContact',
    async ({ bankId, ...props }, { rejectWithValue, dispatch }) => {
        try {
            const res = await partnerApi.deleteCredentialContact({
                ...props,
                bankId,
            });
            if (isResponseOK(res)) {
                dispatch(getOnePartner({ bankId }));
                return res;
            } else {
                throw res;
            }
        } catch (e) {
            return rejectWithValue(getError(e));
        }
    },
);

export const deletePartnerDoc = createAsyncThunk<
    StatusResponseType,
    DeleteBankDocProps,
    {
        rejectValue: string;
    }
>('partner/deletePartnerDoc', async ({ bankId, ...props }, { rejectWithValue, dispatch }) => {
    try {
        const res = await partnerApi.deleteBankDoc({
            ...props,
            bankId,
        });
        if (isResponseOK(res)) {
            dispatch(getOnePartner({ bankId }));
            toast.success('Файл удален');
            return res;
        }
    } catch (e) {
        return rejectWithValue(getError('Ошибка удаления файла'));
    }
});

export const createPartnerDoc = createAsyncThunk<
    StatusResponseType,
    CreateBankDocProps,
    {
        rejectValue: string;
    }
>('partner/createPartnerDoc', async ({ bankId, ...props }, { rejectWithValue, dispatch }) => {
    try {
        const res = await partnerApi.createBankDoc({
            ...props,
            bankId,
        });
        if (isResponseOK(res)) {
            dispatch(getOnePartner({ bankId }));
            toast.success('Файл добавлен');
            return res;
        }
    } catch (e) {
        return rejectWithValue(
            getError('Ошибка загрузки файла. Превышен размер или неподходящий формат.'),
        );
    }
});

export const createBankProductDoc = createAsyncThunk<
    StatusResponseType,
    CreateBankProductDocProps,
    {
        rejectValue: string;
    }
>('partner/createBankProductDoc', async ({ bankId, ...props }, { rejectWithValue, dispatch }) => {
    try {
        const res = await partnerApi.createBankProductDoc({
            ...props,
            bankId,
        });
        if (isResponseOK(res)) {
            dispatch(getOnePartner({ bankId }));
            return res;
        }
    } catch (e) {
        return rejectWithValue(
            getError('Ошибка загрузки файла. Превышен размер или неподходящий формат.'),
        );
    }
});

export const deleteBankProductDoc = createAsyncThunk<
    StatusResponseType,
    DeleteBankProductDocProps,
    {
        rejectValue: string;
    }
>('partner/deleteBankProductDoc', async ({ bankId, ...props }, { rejectWithValue, dispatch }) => {
    try {
        const res = await partnerApi.deleteBankProductDoc({
            ...props,
            bankId,
        });
        if (isResponseOK(res)) {
            dispatch(getOnePartner({ bankId }));
            return res;
        }
    } catch (e) {
        return rejectWithValue(getError('Ошибка удаления файла.'));
    }
});

export const getAllCredentials = createAsyncThunk<
    PartnerCredential[],
    GetAllCredentialsPropsType,
    {
        rejectValue: string;
    }
>('partner/getAllCredentials', async ({ ...props }, { rejectWithValue }) => {
    try {
        const res = await partnerApi.getAllCredentials({ ...props });
        if (res?.message === 'Success') {
            return res.data;
        } else {
            throw res?.data;
        }
    } catch (e) {
        return rejectWithValue(getError(e));
    }
});

const initialState: InitialStateType = {
    companyDocumentCategories: [],
    currentPartner: null,
    archive: '',
    currentDocument: null,
    partnersCount: 0,
    productsByFilter: 0,
    partnersList: [],
    credentialsList: [],
    stopRegions: [],
    productList: [],
    needUpdatePartnerList: false,
    isRequestFulfilled: {
        createProductList: false,
        getPartnerList: false,
        deleteProductList: false,
        uploadFile: false,
    },
    loading: {
        createProductList: false,
        getPartnerList: false,
        deleteProductList: false,
        crudPartner: false,
        uploadPartnerDocs: false,
    },
    error: '',
};

export const partnerSlice = createSlice({
    name: 'partner',
    initialState,
    reducers: {
        clearPartnerState: (state) => {
            state = initialState;
            return state;
        },
        resetCurrentPartner: (state) => {
            state.currentPartner = null;
        },
        setProductsByFilter: (state, { payload }) => {
            state.productsByFilter = payload;
        },
        setNeedUpdatePartnerList: (state, { payload }) => {
            state.needUpdatePartnerList = payload;
            return state;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getOnePartner.fulfilled, (state, { payload }) => {
            state.currentPartner = payload;
        });
        builder.addCase(getStopRegions.fulfilled, (state, { payload }) => {
            state.stopRegions = payload;
        });
        builder.addCase(getStopRegions.rejected, (state, { payload }) => {
            if (typeof payload !== 'string') return;
            toast.error(payload);
            state.error = payload as string;
        });
        builder.addCase(getProductList.fulfilled, (state, { payload }) => {
            state.productList = payload;
        });
        builder.addCase(getProductList.rejected, (state, { payload }) => {
            if (typeof payload !== 'string') return;
            toast.error(payload);
            state.error = payload as string;
        });
        builder.addCase(createProductList.pending, (state) => {
            state.loading.createProductList = true;
            state.isRequestFulfilled.createProductList = false;
        });
        builder.addCase(createProductList.fulfilled, (state) => {
            state.loading.createProductList = false;
            state.isRequestFulfilled.createProductList = true;
        });
        builder.addCase(createProductList.rejected, (state, { payload }) => {
            state.loading.createProductList = false;
            if (typeof payload !== 'string') return;
            toast.error(payload);
            state.error = payload as string;
        });
        builder.addCase(deleteProductList.pending, (state) => {
            state.loading.deleteProductList = true;
            state.isRequestFulfilled.deleteProductList = false;
        });
        builder.addCase(deleteProductList.fulfilled, (state) => {
            state.loading.deleteProductList = false;
            state.isRequestFulfilled.deleteProductList = true;
        });
        builder.addCase(deleteProductList.rejected, (state) => {
            state.loading.deleteProductList = false;
        });
        builder.addCase(getAllPartners.pending, (state) => {
            state.loading.getPartnerList = true;
            state.isRequestFulfilled.getPartnerList = false;
        });
        builder.addCase(createPartnerDoc.pending, (state) => {
            state.loading.uploadPartnerDocs = true;
        });
        builder.addCase(createPartnerDoc.fulfilled, (state) => {
            state.loading.uploadPartnerDocs = false;
            state.isRequestFulfilled.uploadFile = true;
        });
        builder.addCase(getAllPartners.fulfilled, (state, { payload }) => {
            state.loading.getPartnerList = false;
            state.isRequestFulfilled.getPartnerList = true;
            state.partnersList = payload.banksList;
            state.partnersCount = payload.total.records || 0;
        });
        builder.addCase(getAllPartners.rejected, (state, { payload, meta }) => {
            if (meta.aborted) return;
            state.loading.getPartnerList = false;
            if (typeof payload !== 'string') return;
            toast.error(payload);
            state.error = payload as string;
        });
        builder.addCase(getAllCredentials.fulfilled, (state, { payload }) => {
            state.credentialsList = payload;
        });
        builder.addCase(createPartner.pending, (state) => {
            state.loading.crudPartner = true;
        });
        builder.addCase(createPartner.fulfilled, (state) => {
            state.loading.crudPartner = false;
            toast.success('Компания создана');
        });
        builder.addCase(createPartner.rejected, (state, { payload }) => {
            state.loading.crudPartner = false;
            if (typeof payload !== 'string') return;
            toast.error(payload);
            state.error = payload as string;
        });
        builder.addCase(updatePartner.pending, (state) => {
            state.loading.crudPartner = true;
        });
        builder.addCase(updatePartner.fulfilled, (state) => {
            state.loading.crudPartner = false;
            toast.success('Данные обновлены');
        });
        builder.addCase(updatePartner.rejected, (state, { payload }) => {
            state.loading.crudPartner = false;
            if (typeof payload !== 'string') return;
            toast.error(payload);
            state.error = payload as string;
        });
    },
});

export const {
    clearPartnerState,
    resetCurrentPartner,
    setProductsByFilter,
    setNeedUpdatePartnerList,
} = partnerSlice.actions;

export const selectPartner = (state: RootState) => state.partner;
