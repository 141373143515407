import styled from 'styled-components/macro';

const StyledFiltersWrapper = styled.div`
    .calls-filters__filter-row {
        display: flex;
        align-items: center;
        padding: 5px 7px;
        label {
            font-style: normal;
        }
    }
    .calls-filters__user-filter {
        display: flex;
    }
    .calls-filters__date-filter {
        display: flex;
        align-items: center;
        margin-left: 10px;
        input {
            width: 110px;
            height: 20px;
            padding: 5px;
            margin: 0 10px;
            border: 0.5px solid #000000;
            border-radius: 5px;
            cursor: pointer;
        }
    }
    .calls-filters__date-filter-label {
        width: 83px;
    }
    .export__button {
        display: flex;
        align-items: center;
        cursor: pointer;
        width: fit-content;
        p {
            margin-left: 10px;
            display: flex;
            align-items: center;
            font-weight: 500;
            font-size: 14px;
            line-height: 20px;
            text-decoration-line: underline;
            color: #0038ff;
        }
    }
`;

export default StyledFiltersWrapper;
