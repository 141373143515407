import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';

import StyledCallsWrapper from './CallsComponent.style';
import CallsTable from './CallsTable/CallsTable';
import { useAppDispatch } from '../../../store';
import { getAllCalls, selectCalls } from '../../../store/slices/callsSlice';
import { userSelector } from '../../../store/slices/userSlice';
import { callsTabTableColumns, callsTableColumns } from '../../../utils/data';
import { callsPageTexts } from '../../../utils/consts';
import { getDigitsFromString, strWithDateToIsoStr } from '../../../utils/functions';
import { useNavigate } from 'react-router-dom';
import { getAllUsers } from '../../../store/slices/staffSlice';
import queryString from 'query-string';
import { CallsRecordInterface } from '../../../utils/types';

import CallsPlayer from '../../../components/Common/CallsPlayer/CallsPlayer';
import {
    CallsComponentProps,
    CallsFilterParams,
    CallsSearchParamsFieldNames,
} from '../../../components/Common/CallsComponent/CallsComponent.types';
import { callsDefaultFilterParams } from '../../../components/Common/CallsComponent/CallsComponent.consts';
import CallsFilters from '../../../components/Common/CallsFilters/CallsFilters';

const CallsComponent = ({
    isTabView = false,
    currentCompanyId,
    hideManagerFilter = false,
}: CallsComponentProps) => {
    const firstPageUpdate = useRef(true);
    const navigate = useNavigate();
    const { loading, callRecords, isRequestFulfilled } = useSelector(selectCalls);
    const { user } = useSelector(userSelector);
    const dispatch = useAppDispatch();

    const [filter, setFilter] = useState<CallsFilterParams>(callsDefaultFilterParams);

    const promiseRef = useRef<{ abort: () => void } | undefined>();

    const [callInPlayer, setCallInPlayer] = useState<CallsRecordInterface | null>(null);
    const [isPlayingRecord, setPlayingRecord] = useState(false);
    const audioPlayer = useRef<HTMLAudioElement>(null);

    const isAdmin = user?.isAdmin;
    const disabledAllFilters = loading.calls;
    const columnHeadings = isTabView ? callsTabTableColumns : callsTableColumns;

    const handleFilter = () => {
        if (isTabView && currentCompanyId === 0) {
            return;
        }
        const managerId = Number(filter.manager) || undefined;
        const dateFrom = strWithDateToIsoStr(filter.dateFrom);
        const dateTo = strWithDateToIsoStr(filter.dateTo);
        const direction = filter.direction || undefined;
        const phone = filter.phone || undefined;
        const duration = Number(filter.duration) || undefined;
        const companyId = isTabView ? currentCompanyId : undefined;

        const searchParams = {
            managerId,
            dateFrom,
            dateTo,
            direction,
            phone,
            duration,
            companyId,
        };
        promiseRef.current?.abort();
        promiseRef.current = dispatch(
            getAllCalls({
                ...searchParams,
            }),
        );
    };
    const filterChangeHandler = (value: string, field?: string) => {
        if (field === 'phone') {
            setFilter({
                ...callsDefaultFilterParams,
                phone: getDigitsFromString(value),
            });
            return;
        }
        if (field) {
            setFilter({
                ...filter,
                [field]: value,
            });
            return;
        }
        setFilter({
            ...filter,
            direction: value,
        });
    };

    const serializeSearchParams = (obj: CallsFilterParams) => {
        const str: string[] = [];
        Object.getOwnPropertyNames(obj).forEach((key) => {
            if (key in obj && obj[key as CallsSearchParamsFieldNames]) {
                const encodedKey = encodeURIComponent(key);
                const encodedValue = encodeURIComponent(obj[key as CallsSearchParamsFieldNames]);
                str.push(`${encodedKey}=${encodedValue}`);
            }
        });
        return str.join('&');
    };

    const saveFilter = () => {
        if (isTabView) {
            return;
        }
        const query = serializeSearchParams({
            ...filter,
        });
        navigate({
            ...location,
            search: query,
        });
    };

    useEffect(() => {
        if (firstPageUpdate.current) {
            firstPageUpdate.current = false;
            return;
        }
        saveFilter();
        handleFilter();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filter]);

    useEffect(() => {
        if (!isRequestFulfilled.updateCall) {
            return;
        }
        saveFilter();
        handleFilter();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isRequestFulfilled.updateCall]);

    useEffect(() => {
        if (currentCompanyId === 0) {
            return;
        }
        handleFilter();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentCompanyId]);

    useEffect(() => {
        dispatch(getAllUsers({ onlyActiveUsers: true }));
        const queryStringParams = queryString.parse(location.search);

        if (!queryStringParams) {
            setFilter(callsDefaultFilterParams);
            return;
        }

        const manager = isAdmin ? (queryStringParams?.manager as string) || filter.manager : '';
        const dateFrom = (queryStringParams?.dateFrom as string) || filter.dateFrom;
        const dateTo = (queryStringParams?.dateTo as string) || filter.dateTo;
        const direction = (queryStringParams?.direction as string) || filter.direction;
        const phone = (queryStringParams?.phone as string) || filter.phone;
        const duration = (queryStringParams?.duration as string) || filter.duration;

        const newFilter = {
            manager,
            dateFrom,
            dateTo,
            direction,
            phone,
            duration,
        };
        setFilter(newFilter);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const playRecord = (record: CallsRecordInterface) => {
        if (!audioPlayer.current || !record) {
            return;
        }
        if (record.callId !== callInPlayer?.callId) {
            audioPlayer.current.src = record.recordUrl || '';
            setCallInPlayer(record);
        }
        audioPlayer.current.play();
        setPlayingRecord(true);
    };

    const pauseRecord = (record: CallsRecordInterface) => {
        const noData = !audioPlayer.current || !record || !callInPlayer;
        const anotherCallId = record.callId !== callInPlayer!.callId;
        if (noData || anotherCallId) {
            return;
        }
        audioPlayer.current.pause();
        setPlayingRecord(false);
    };

    return (
        <StyledCallsWrapper>
            {!isTabView && (
                <div className="calls__heading">
                    <h5>{callsPageTexts.callRecord}</h5>
                </div>
            )}
            <CallsFilters
                filterChangeHandler={filterChangeHandler}
                disabledAllFilters={disabledAllFilters}
                filter={filter}
                user={user}
                hideManagerFilter={hideManagerFilter}
                callRecords={callRecords}
            />
            <CallsTable
                callRecords={callRecords}
                callsLoading={loading.calls}
                tableColumns={columnHeadings}
                user={user}
                callInPlayer={callInPlayer}
                isPlayingRecord={isPlayingRecord}
                playRecord={playRecord}
                pauseRecord={pauseRecord}
                isTabView={isTabView}
            />
            <CallsPlayer
                callInPlayer={callInPlayer}
                audioPlayer={audioPlayer}
                isPlayingRecord={isPlayingRecord}
                setPlayingRecord={setPlayingRecord}
            />
        </StyledCallsWrapper>
    );
};

export default CallsComponent;
