import React, { useMemo } from 'react';
import clsx from 'clsx';

import {
    formatNum,
    getBankProductAcronym,
    getClosedOffer,
    getDate,
    getLinkForTask,
    getTaskNumber,
    getTooltipLength,
    getUserName,
} from '../../../../utils/functions';
import TaskCommissionFields from '../TaskCommissionFields/TaskCommissionFields';
import ClosedRequestsStatus from '../ClosedRequestsStatus/ClosedRequestsStatus';
import InfoIcon from '../../../../assets/images/partners/additional-info-icon.png';
import RestoreIcon from '../../../../assets/images/tasks/restore-icon.svg';
import ClosedRequestIncome from '../ClosedRequestIncome/ClosedRequestIncome';
import TooltipReminder from '../../../../components/Common/TooltipReminder/TooltipReminder';
import LoanProductLabel from '../../../../components/LoanProductLabel/LoanProductLabel';
import ConfirmModal from '../../../../components/Common/ConfirmModal/ConfirmModal';
import { useAppDispatch } from '../../../../store';
import { updateTask } from '../../../../store/slices/clientTaskSlice';
import { ClientTaskInterface, TaskProductENUM } from '../../../../utils/types';

type ClientClosedRequestsTableRowProps = {
    task: ClientTaskInterface;
    index: number;
    isAdmin?: boolean;
};
const ClientClosedRequestsTableRow = ({
    task,
    index,
    isAdmin,
}: ClientClosedRequestsTableRowProps) => {
    const dispatch = useAppDispatch();
    const [updateTaskId, setUpdateTaskId] = React.useState<number | null | undefined>(null);

    const isLoan = task.type === TaskProductENUM.LOAN;
    const tableRowClass = useMemo(
        () =>
            clsx('requests-table__row', {
                'requests-table__even-row': index % 2 !== 0,
            }),
        [index],
    );
    const closeDate = useMemo(
        () => getDate(task.closeDate, 'date with time') || '---',
        [task.closeDate],
    );
    const taskNumber = useMemo(() => {
        return {
            value: getTaskNumber(task),
            link: getLinkForTask(task),
        };
    }, [task]);
    const bankProductAcronym = useMemo(
        () => getBankProductAcronym(isLoan ? task.loanType : task.bankGuaranteeType),
        [isLoan, task.bankGuaranteeType, task.loanType],
    );
    const bankName = useMemo(() => getClosedOffer(task)?.bankProduct.bank.name || '---', [task]);
    const loanLabel = isLoan ? <LoanProductLabel /> : null;
    const amount = useMemo(() => {
        const productAmount = isLoan ? task.loanAmount : task.bankGuaranteeAmount;
        return formatNum(productAmount || '');
    }, [isLoan, task.bankGuaranteeAmount, task.loanAmount]);
    const userName = useMemo(() => getUserName(task.executor), [task.executor]);
    const importantInfoLength = useMemo(
        () =>
            getTooltipLength({
                textLength: task.importantInfo?.length,
                minLetters: 10,
                letterWidth: 5,
            }),
        [task.importantInfo?.length],
    );

    const onUpdateTaskClick = () => {
        setUpdateTaskId(task.taskId);
    };

    const handleTaskUpdate = async (taskId: number) => {
        await dispatch(
            updateTask({
                userWhoClosedTask: null,
                taskId: taskId,
                closeReason: null,
                closeDate: null,
                closeComment: null,
                companyId: task.company.companyId,
                type: task.type,
            }),
        ).unwrap();
    };

    const shouldShowButton = useMemo(
        () =>
            !!(
                task.closeDate &&
                task.closeReason &&
                task.userWhoClosedTask &&
                task.status !== 'closed' &&
                isAdmin
            ),
        [isAdmin, task.closeDate, task.closeReason, task.status, task.userWhoClosedTask],
    );

    return (
        <div className={tableRowClass}>
            <p>{closeDate}</p>
            <div>{loanLabel}</div>
            <a
                className="requests-table__number"
                target="_blank"
                rel="noopener noreferrer"
                href={taskNumber.link}
            >
                {taskNumber.value}
            </a>
            <p>{bankProductAcronym}</p>
            <p>{bankName}</p>
            <div className="close-requests-table__right-position">
                <p>{amount}</p>
            </div>
            <TaskCommissionFields task={task} />
            <ClosedRequestIncome task={task} />
            <ClosedRequestsStatus task={task} />
            <p>{userName}</p>
            {shouldShowButton && (
                <img
                    src={RestoreIcon}
                    className="requests-table__button"
                    onClick={onUpdateTaskClick}
                />
            )}
            {task.importantInfo ? (
                <TooltipReminder
                    text={task.importantInfo || ''}
                    hasReminder={Boolean(task.importantInfo)}
                    rightMargin={40}
                    maxWidth={800}
                    minWidth={importantInfoLength}
                >
                    <img className="requests-table__info-icon" src={InfoIcon} />
                </TooltipReminder>
            ) : null}
            {updateTaskId && (
                <ConfirmModal
                    close={() => setUpdateTaskId(null)}
                    confirmAction={() => handleTaskUpdate(updateTaskId)}
                    width={280}
                    height={85}
                    headText="Хотите восстановить заявку?"
                />
            )}
        </div>
    );
};

export default ClientClosedRequestsTableRow;
