import styled from 'styled-components/macro';

const StyledTable = styled.div`
    font-weight: 300;
    font-size: 12px;
    margin-bottom: 10px;
    overflow-x: scroll;

    .requests-table__title {
        padding: 5px 17px 5px 7px;
        height: 30px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        background: #e5e5e5;

        p {
            font-weight: 600;
        }

        span {
            font-weight: 300;
        }
    }

    .requests-table__column {
        display: flex;
        align-items: center;
        cursor: pointer;
        font-weight: 600;

        img {
            width: 12px;
            height: 12px;
            margin-left: 5px;
        }
    }

    .requests-table__headings,
    .requests-table__row {
        line-height: 20px;
        display: grid;
        align-items: center;
        grid-template-columns:
            minmax(120px, 180px) 40px minmax(60px, 90px) minmax(40px, 70px)
            minmax(160px, 2fr) repeat(5, minmax(140px, 1fr)) 20px minmax(130px, 200px) 34px 40px;
        grid-column-gap: 10px;
        padding: 5px 7px;
        a {
            width: fit-content;
            min-width: 20px;
        }
    }

    .requests-table__button {
        cursor: pointer;
        width: 32px;
        height: 32px;
    }

    .requests-table__number {
        color: #0038ff;
        text-decoration: underline;
    }

    .requests-table__icons {
        display: flex;
        align-items: start;
        justify-content: end;

        img {
            margin-left: 20px;
        }
    }

    .requests-table__edit-icon {
        cursor: pointer;
    }

    .requests-table__commission span {
        font-weight: 600;
    }

    .requests-table__green-field {
        color: #00892f;
    }

    .requests-table__red-field {
        color: #ff0000;
    }

    .requests-table__even-row {
        background: #f5f5f5;
    }

    .close-requests-table__right-position,
    .requests-table__commission {
        display: flex;
        justify-content: flex-end;
    }
    .row-cell-prio {
        display: flex;
        justify-content: flex-end;
        font-weight: bold;
        &--0 {
            grid-column: 8;
        }
        &--1 {
            grid-column: 9;
            color: #ff0000;
        }
        &--2 {
            grid-column: 10;
            color: #00892f;
        }
    }
`;

export default StyledTable;
